import { Link } from "react-router-dom";

function Button({ link, name, className, onClick }) {
  return (
    <Link to={link || ""} >
      <div
        className={`cursor-pointer border border-Secondary py-3 my-auto rounded-2xl text-white font-semibold text-lg text-center bg-gradient-to-l from-Main to-Secondary hover:text-yellow-400 hover:text-[19px] transition  ${className}`}
        onClick={onClick}
        // data-aos="fade-down"
        // data-aos-duration="100"
      >
        {name}
      </div>
    </Link>
  );
}
export default Button;
