import React from "react";
import "./Circle.css";
function Circle({ img, title }) {
  return (
    <>
      <div className=" space-y-5  mx-auto  ">
        <div
          className=" bg-Main w-[180px] h-[170px] rounded-full flex justify-center items-center mt-1 max-lg:-[w-90%] "
          // data-aos="fade-zoom-in"
          // data-aos-easing="ease-in-back"
          // data-aos-delay="0"
          // data-aos-offset="0"
        >
          <div
            className="border-[15px] !border-yellow-500 h-[130px] w-[130px] rounded-full  flex justify-center items-center"
            // data-aos="fade-zoom-in"
            // data-aos-easing="ease-in-back"
            // data-aos-delay="400"
            // data-aos-offset="0"
          >
            <img
              src={img}
              alt=""
              className=""
              // data-aos="zoom-in-up"
              // data-aos-delay="600"
            />
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div
            className="triangle "
            // data-aos="zoom-in-down"
            // data-aos-delay="800"
          ></div>
        </div>
        <div className="flex justify-center items-center">
          <p
            className="text-black font-semibold"
            // data-aos="zoom-in-down"
            // data-aos-delay="1000"
          >
            {title}
          </p>
        </div>
      </div>
    </>
  );
}

export default Circle;
