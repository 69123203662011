import React from "react";
import { direction } from "../../Tools/APIs";

function CardProcess({ title, text }) {
  return (
    <div
      style={{ direction: !direction ? "ltr" : "rtl" }}
      className="border-2 border-Main rounded-lg p-2 w-full h-full"
      // data-aos="fade-down"
      // data-aos-duration="1000"
    >
      <p className="text-sm text-Main font-bold mb-3 max-lg:text-xl ">
        {title}
      </p>
      <span
        style={{ direction: !direction ? "rtl" : "rtl", color: "#0F172A " }}
        className="text-sm  font-semibold max-lg:text-xl max-sm:text-sm"
      >
        {text}
      </span>
    </div>
  );
}

export default CardProcess;
