import React, { useEffect } from "react";
import { Col, Container, Row } from "../../Tools/Grid-system";
import AOS from "aos";
import "aos/dist/aos.css";
import { SlArrowDown } from "react-icons/sl";
import { CardProcess, Title } from "../../components/Index";
import l_1 from "../../Images/Group 427320055.png";
import l_2 from "../../Images/02 (1).png";
import l_3 from "../../Images/03.png";
import l_4 from "../../Images/04.png";
import { direction } from "../../Tools/APIs";
import { useContextTranslate } from "../../Translate/ContextTranslate";

function OurProcess() {
  const { content } = useContextTranslate();
  // useEffect(() => {
  //   AOS.init({}); // Initialize AOS
  // }, []);

  return (
    <Container className="overflow-hidden">
      <Row>
        <Col className="flex justify-center items-center ">
          <Title name={content.ourprocess} />
        </Col>
        <Row>
          <Col lg={11} className="mx-auto ">
            <p
              className="text-black text-start font-bold text-xl max-lg:text-lg max-lg:text-cente "
              // data-aos="fade-up"
              // data-aos-duration="1000"
            >
              {content.TheProcess}
            </p>
            <p
              className="text-black text-start font-bold text-xl max-lg:text-lg max-lg:text-center"
              // data-aos="fade-right"
              // data-aos-duration="1000"
            >
              {content.text}
              <br />
              <br />
              {content.text1}
            </p>
          </Col>
        </Row>

        <Col
          style={{ direction: !direction ? "ltr" : "ltr" }}
          lg={11}
          className="mx-auto pt-10"
        >
          <Col className="flex flex-col mx-auto py-14">
            <div class="grid grid-cols-3 divide-x    divide-black border-2 border-black w-full h-full rounded-3xl border-l-transparent max-md:border-l-black max-md:grid max-md:grid-cols-1 max-md:divide-y rounded-es-none rounded-ss-none max-md:border-none max-md:divide-none ">
              <div
                className=" max-md:order-1 max-lg:flex max-lg:justify-center max-lg:items-center"
                // data-aos="fade-right"
                // data-aos-duration="1000"
              >
                <Col lg={7} className="flex flex-col mx-auto py-14    ">
                  <img
                    src={l_2}
                    alt=""
                    // className=""
                    // data-aos="fade-up"
                    // data-aos-duration="1000"
                  />
                  <p
                    className="text-black text-xl font-bold w-full text-center max-md:text-2xl "
                    // data-aos="fade-down"
                    // data-aos-duration="1000"
                  >
                    {content.SprintDesign}
                  </p>
                </Col>
              </div>
              <div className="col-span-2 max-md:row-span-3 max-md:order-2 relative pl-12 pb-5 max-lg:flex max-lg:justify-center max-lg:items-center max-lg:pl-0 ">
                <div className="absolute top-1/2 left-4 -translate-x-1/2 -translate-y-1/2 max-xl:hidden z-50 bg-white -rotate-90   w-fit">
                  <SlArrowDown
                    size={70}
                    color="#36549D"
                    className=""
                    // data-aos="fade-up"
                    // data-aos-duration="1000"
                  />
                </div>
                <div className="absolute -bottom-16 right-1/3 translate-x-1/2 -translate-y-1/2 max-xl:hidden z-50 bg-white   w-fit pt-1">
                  <SlArrowDown
                    size={50}
                    color=""
                    className=""
                    // data-aos="fade-down"
                    // data-aos-duration="1000"
                  />
                </div>
                <Row className="mx-auto flex-row p-2 ">
                  <Col lg={6} className="mx-auto">
                    <CardProcess
                      title={content.Softwarespecification}
                      text={content.text2}
                    />
                  </Col>
                  <Col lg={6} className="mx-auto">
                    <CardProcess
                      title={content.Softwarespecification}
                      text={content.text2}
                    />
                  </Col>
                  <Col lg={6} className="mx-auto">
                    <CardProcess
                      title={content.Softwarespecification}
                      text={content.text2}
                    />
                  </Col>
                  <Col lg={6} className="mx-auto">
                    <CardProcess
                      title={content.Softwarespecification}
                      text={content.text2}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <div class="grid grid-cols-3 divide-x divide-black border-2 border-black w-full h-full rounded-3xl border-r-transparent border-t-white max-md:border-r-black max-md:border-t-black  max-md:grid max-md:grid-cols-1 max-md:divide-y rounded-ss-none rounded-ee-none max-md:border-none max-md:divide-none ">
              <div className="col-span-2 max-md:row-span-3 max-md:order-2  relative max-lg:flex max-lg:justify-center max-lg:items-center ">
                <Row>
                  <Col
                    lg={11}
                    className="mx-auto flex justify-center items-center py-20 "
                  >
                    <img
                      src={l_1}
                      alt=""
                      className=" max-md:h-[180px]"
                      // data-aos="fade-right"
                      // data-aos-duration="1000"
                    />
                  </Col>
                </Row>
                <div className="absolute top-1/2 -right-14 -translate-x-1/2 -translate-y-1/2 max-xl:hidden z-50 bg-white rotate-90   w-fit">
                  <SlArrowDown
                    size={70}
                    color="#36549D"
                    className=""
                    // data-aos="fade-up"
                    // data-aos-duration="1000"
                  />
                </div>
                <div className="absolute -bottom-3 left-1/3 -translate-x-1/2 translate-y-1/2 max-xl:hidden z-50 bg-white   w-fit pt-1 ">
                  <SlArrowDown
                    size={50}
                    color=""
                    className=""
                    // data-aos="fade-up"
                    // data-aos-duration="1000"
                  />
                </div>
              </div>
              <div className="max-md:order-1 max-lg:flex max-lg:justify-center max-lg:items-center ">
                <Row>
                  <Col lg={7} className="flex flex-col mx-auto py-14    ">
                    <img
                      src={l_2}
                      alt=""
                      className=""
                      // data-aos="fade-up"
                      // data-aos-duration="1000"
                    />
                    <p
                      className="text-black text-xl font-bold w-full text-center max-md:text-2xl "
                      // data-aos="fade-down"
                      // data-aos-duration="1000"
                    >
                      {content.SprintDesign}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
            <div class="grid grid-cols-3 divide-x divide-black border-2 border-black w-full h-full rounded-3xl border-l-transparent border-t-white max-md:border-t-black max-md:border-r-black max-md:grid max-md:grid-cols-1 max-md:divide-y rounded-es-none rounded-se-none max-md:border-none max-md:divide-none">
              <div className="max-lg:flex max-lg:justify-center max-lg:items-center">
                <Row>
                  <Col lg={7} sm={7} className="flex flex-col mx-auto pt-14  ">
                    <img
                      src={l_3}
                      alt=""
                      className=""
                      // data-aos="fade-up"
                      // data-aos-duration="1000"
                    />
                    <p className="text-black text-lg font-bold w-full text-center max-md:text-2xl">
                      {content.DesignApproach}
                    </p>
                  </Col>
                  <Col lg={11} className="mx-auto ">
                    <p
                      className=" text-sm font-bold w-full text-start max-md:text-xl max-md:my-10 max-md:text-center"
                      // data-aos="fade-up"
                      // data-aos-duration="1000"
                      style={{ color: "#0F172A " }}
                    >
                      {content.text5}
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="col-span-2 max-md:row-span-3 relative pl-12 pb-5 max-lg:flex max-lg:justify-center max-lg:items-center max-lg:pl-0">
                <div className="absolute top-1/2 left-4 -translate-x-1/2 -translate-y-1/2 max-xl:hidden z-50 bg-white -rotate-90  w-fit">
                  <SlArrowDown
                    size={70}
                    color="#36549D"
                    className=""
                    // data-aos="fade-up"
                    // data-aos-duration="1000"
                  />
                </div>
                <div className="absolute -bottom-16 right-1/3 translate-x-1/2 -translate-y-1/2 max-xl:hidden z-50 bg-white   w-fit pt-1">
                  <SlArrowDown
                    size={50}
                    color=""
                    className=""
                    // data-aos="fade-down"
                    // data-aos-duration="1000"
                  />
                </div>
                <Row className="mx-auto flex-row p-2 ">
                  <Col lg={6} className="mx-auto">
                    <CardProcess
                      title={content.UserResearch}
                      text={content.text4}
                    />
                  </Col>
                  <Col lg={6} className="mx-auto">
                    <CardProcess
                      title={content.CompetitorAnalysis}
                      text={content.text3}
                    />
                  </Col>
                  <Col lg={6} className="mx-auto">
                    <CardProcess
                      title={content.Softwarespecification}
                      text={content.text2}
                    />
                  </Col>
                  <Col lg={6} className="mx-auto">
                    <CardProcess
                      title={content.Softwarespecification}
                      text={content.text2}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <div class="grid grid-cols-3 divide-x divide-black border-2 border-black w-full h-full rounded-3xl border-r-transparent border-t-white border-b-white max-md:border-t-black max-md:border-b-black max-md:border-r-black max-md:grid max-md:grid-cols-1 max-md:divide-y rounded-ss-none max-md:border-none max-md:divide-none">
              <div className="col-span-2 max-md:row-span-3  max-md:order-2 relative pr-10 max-lg:flex max-lg:justify-center max-lg:items-center max-lg:pr-0">
                <Row className="mx-auto flex-row p-2 ">
                  <Col lg={6} className="mx-auto">
                    <CardProcess
                      title={content.UserResearch}
                      text={content.text4}
                    />
                  </Col>
                  <Col lg={6} className="mx-auto">
                    <CardProcess
                      title={content.CompetitorAnalysis}
                      text={content.text3}
                    />
                  </Col>
                  <Col lg={6} className="mx-auto">
                    <CardProcess
                      title={content.Softwarespecification}
                      text={content.text2}
                    />
                  </Col>
                  <Col lg={6} className="mx-auto">
                    <CardProcess
                      title={content.Softwarespecification}
                      text={content.text2}
                    />
                  </Col>
                </Row>
                <div className="absolute top-1/2 -right-14 -translate-x-1/2 -translate-y-1/2 max-xl:hidden z-50 bg-white rotate-90 pl-1  w-fit">
                  <SlArrowDown
                    size={70}
                    color="#36549D"
                    className=""
                    // data-aos="fade-up"
                    // data-aos-duration="1000"
                  />
                </div>
              </div>
              <div className=" max-md:order-1 max-lg:flex max-lg:justify-center max-lg:items-center">
                <Row>
                  <Col lg={7} sm={6} className="flex flex-col mx-auto pt-14 ">
                    <img
                      src={l_4}
                      alt=""
                      // data-aos="fade-up"
                      // data-aos-duration="1000"
                    />
                    <p className="  font-bold w-full text-center max-md:text-2xl ">
                      {content.QualityAssurance}
                    </p>
                  </Col>
                  <Col lg={11} className="mx-auto mb-8">
                    <p
                      className="text-black text-sm font-bold w-full text-start max-md:text-xl max-md:text-center  max-md:my-10 "
                      // data-aos="fade-up"
                      // data-aos-duration="1000"
                      style={{ color: "#0F172A " }}
                    >
                      {content.text5}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default OurProcess;
