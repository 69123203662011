import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "../../Tools/Grid-system";
import PrintHtml from "../PrintHTML/PrintHtml";

function Blog2({ picture, date, time, info, className, edit }) {
  return (
    <Row className="!mt-10 hover:scale-105 transition-all duration-150">
      <Link
        to={edit || ""}
        className="w-full flex gap-5 h-fit max-md:flex-col max-md:items-center"
      >
        <Col md={4} className="">
          {picture && (
            <img
              src={picture}
              alt=""
              className="rounded-lg w-32 h-32"
              // data-aos="fade-left"
              // data-aos-offset="200"
              // data-aos-easing="ease-in-sine"
              // data-aos-duration="500"
            />
          )}
        </Col>
        <Col md={8} className="gap-2 flex flex-col justify-between">
          <p
            className="text-slate-900 text-md max-md:text-center max-md:text-xl"
            // data-aos="fade-left"
            // data-aos-offset="200"
            // data-aos-easing="ease-in-sine"
            // data-aos-duration="500"
          >
            <PrintHtml text={info} />
          </p>
          <p
            className="text-slate-500 max-md:text-lg "
            // data-aos="fade-left"
            // data-aos-offset="200"
            // data-aos-easing="ease-in-sine"
            // data-aos-duration="500"
          >
            {date} <strong className="text-xl">-</strong> {time}
          </p>
        </Col>
      </Link>
    </Row>
  );
}

export default Blog2;
