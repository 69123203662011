import { RiArrowDownSLine } from "react-icons/ri";
import { MdLanguage } from "react-icons/md";
import { useContextTranslate } from "./ContextTranslate";
import { useClose } from "../Tools/APIs";


function Translation() {
  const { reverse, changeLanguage, changeLanguage2 } = useContextTranslate();
  const { open, mouse, setOpen } = useClose();
  return (
    <div ref={mouse}>
      <div className="relative cursor-pointer" onClick={() => setOpen(!open)}>
        <div
          className={`flex items-center justify-center gap-1 ${
            reverse ? "flex-row-reverse" : ""
          }`}
        >
          <MdLanguage className="" size={30} />
          <RiArrowDownSLine
            size={20}
            className={`${
              open ? "rotate-180 transition-all" : "rotate-0 transition-all"
            }`}
          />
        </div>
        <ul
          className={`${
            open
              ? "flex-col absolute right-[1px] top-8 rounded-xl text-center bg-gray-500 text-white z-50 w-16 mx-auto"
              : "hidden"
          }`}
        >
          <li
            className="border-b pb-1 pt-2 rounded-t-xl cursor-pointer hover:bg-[#FCD68B] hover:text-[#375499]"
            onClick={changeLanguage2}
          >
            English
          </li>
          <li
            className="pt-1  pb-2 rounded-b-xl cursor-pointer hover:bg-[#FCD68B] hover:text-[#375499]"
            onClick={changeLanguage}
          >
            العربية
          </li>
        </ul>
      </div>
    </div>
  );
}
export default Translation;
