import React from "react";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Title from "../../components/Title/Title";
import { CardMel } from "../../components/Index";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { fileUrl, useFETCH, useFilter } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import Pagination from "../../Tools/Pagination";

function Services() {
  const { searchParams } = useFilter();
  const { data, isLoading } = useFETCH(
    `services?page=${searchParams.get("page")}`
  );

  const { content } = useContextTranslate();
  return (
    <Container className="overflow-hidden">
      {isLoading && <Loading />}
      <Row>
        <Col className="flex justify-center items-center ">
          <Title name={content.ourservices} />
        </Col>
      </Row>
      <Pagination
        pageCount={data?.data?.total / data?.data?.per_page}
        isLoading={isLoading}
      >
        <Row className="flex justify-between items-center mx-auto pt-1 space-y-10">
          <Col
            lg={12}
            className="flex justify-between items-center mx-auto max-lg:flex-col max-lg:space-y-16 pt-10 gap-5 flex-wrap"
          >
            {data?.data?.data?.map((e) => (
              <Col lg={3} md={5} xs={8} className="mx-auto space-y-20">
                <CardMel
                  picture={fileUrl + e.image}
                  title={e.name}
                  a={content.view}
                  text={e.description}
                  edit={`view/${e.id}`}
                />
              </Col>
            ))}
          </Col>
        </Row>
      </Pagination>
    </Container>
  );
}

export default Services;
