import { FaGooglePlay, FaWhatsapp } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { IoLogoApple } from "react-icons/io5";
import { LuFacebook } from "react-icons/lu";
import { SiWebflow } from "react-icons/si";
import { useParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { clickZoomInImage, fileUrl, useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Swipper, Title } from "../../components/Index";
import PrintHtml from "../../components/PrintHTML/PrintHtml";

function ContentProject() {
  const { id } = useParams();
  const { data, isLoading } = useFETCH(`projects/${id}`);

  const socialMediaLinks = [
    {
      link: data?.data?.data?.instagram,
      icon: FiInstagram,
    },
    {
      link: data?.data?.data?.facebook,
      icon: LuFacebook,
    },
    {
      link: data?.data?.data?.whatsapp,
      icon: FaWhatsapp,
    },
  ];

  const appLinks = [
    {
      link: data?.data?.data?.web,
      icon: SiWebflow,
      text: "Link to website",
    },
    {
      link: data?.data?.data?.google_play,
      icon: FaGooglePlay,
      text: "Link to Google Play",
    },
    {
      link: data?.data?.data?.app_store,
      icon: IoLogoApple,
      text: "Link to App Store",
    },
  ];

  const { content } = useContextTranslate();
  return (
    <Container className="bg overflow-hidden">
      {isLoading && <Loading />}
      <Row>
        <Col className="flex justify-center items-center  max-xl:pb-0 ">
          <Title name={content.ourprojects} />
        </Col>
        <Row className="mx-auto" gap={5}>
          <Col md={6} className="mx-auto">
            <img
              src={fileUrl + data?.data?.data?.image}
              alt=""
              onClick={clickZoomInImage}
              className="cursor-pointer w-full h-full"
            />
          </Col>
          <Col
            md={11}
            className="mx-auto max-md:flex max-md:justify-center max-md:items-center"
          >
            <span className="text-black text-start font-bold text-2xl ">
              {data?.data?.data.name}
            </span>
          </Col>
        </Row>
        <Row className="mx-auto">
          <Col sm={11} className="mx-auto">
            <span className="text-black text-center font-bold text-xl max-lg:text-lg max-sm:text-sm max-lg:text-center">
              <PrintHtml text={data?.data?.data?.description} />
            </span>
          </Col>
        </Row>

        <Row className="mx-auto">
          <Col className="mx-auto  ">
            <Swipper data={data?.data?.data?.files} />
          </Col>
        </Row>
        <Row className="mx-auto">
          <Col className="mx-auto flex justify-around max-md:flex-col flex-wrap">
            <Col
              md={11}
              className="flex justify-center flex-wrap items-center gap-5 pt-4 max-md:flex max-md:justify-center max-md:items-center "
            >
              {appLinks?.map((e) => (
                <a
                  href={e.link}
                  target="_blank"
                  className="flex justify-between items-center p-3 gap-2 flex-wrap bg-black rounded-lg"
                >
                  <e.icon size={25} color="white" />
                  <div className="text-white font-semibold text-lg">
                    {e.text}
                  </div>
                </a>
              ))}
            </Col>
            <Col
              md={11}
              className="flex justify-center flex-wrap items-center gap-3 pt-4 max-md:flex max-md:justify-center max-md:items-center "
            >
              {socialMediaLinks?.map((e) => (
                <a href={e.link} target="_blank">
                  <e.icon size={40} color="blue" />
                </a>
              ))}
            </Col>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}

export default ContentProject;
