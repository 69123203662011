function Input({
  title,
  name,
  value,
  type,
  onChange,
  placeholder,
  className,
  icon,
}) {
  return (
    <div
      className="my-7 px-5 mx-auto border-Main"
      //   data-aos="fade-up"
      // data-aos-duration="1000"
    >
      {title && (
        <h1 className="text-start text-xl text-black font-bold mb-3   ">
          {title}
        </h1>
      )}
      <input
        type={type || "text"}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        max={100}
        min={0}
        className={`w-full  mx-auto py-4 bg-gray-100 rounded-xl text-start border-2 !border-Main  ${className}`}
      />
      {icon}
    </div>
  );
}
export default Input;
