import React, { useEffect } from "react";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { FiUpload } from "react-icons/fi";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Button,
  Input,
  InputCeck,
  TextArea,
  Title,
} from "../../components/Index";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { language, useFETCH, usePOST } from "../../Tools/APIs";
import { FaRegFilePdf } from "react-icons/fa";
import Loading from "../../Tools/Loading";

function HireUs() {
  const { data: services, isLoading: isLoadingServices } =
    useFETCH(`service-types`);

  const { data: systems, isLoading: isLoadingSystem } =
    useFETCH(`system-types`);

  const {
    setFormData,
    handleSubmit,
    handleChangeInput,
    formData,
    file,
    setFile,
    setViewFile,
    viewFile,
  } = usePOST({
    name: "",
    email: "",
    phone: "",
    message: "",
    target_audience: "",
    expected_starting_date: "",
    expected_ending_date: "",
    expected_budget: "",
    service_type_ids: [],
    system_type_ids: [],
  });

  useEffect(() => {
    setFormData({
      name: "",
      email: "",
      phone: "",
      message: "",
      target_audience: "",
      expected_starting_date: "",
      expected_ending_date: "",
      expected_budget: "",
      service_type_ids: [],
      system_type_ids: [],
    });
    setFile({});
    setViewFile("");
  }, []);

  const handleChangeCheck = (event, id) => {
    if (event.target.checked) {
      setFormData({
        ...formData,
        [`${event.target.name}`]: [...formData[`${event.target.name}`], id],
      });
    } else {
      setFormData({
        ...formData,
        [`${event.target.name}`]: formData[`${event.target.name}`].filter(
          (e) => e !== id
        ),
      });
    }
  };

  const handleDeleteFile = () => {
    setFile({});
    setViewFile("");
  };

  const { content } = useContextTranslate();
  // useEffect(() => {
  //   AOS.init({
  //     offset: 20,
  //     delay: 300,
  //     easing: "ease-in-out",
  //     duration: 500,
  //   }); // Initialize AOS
  // }, []);
  return (
    <Container className="overflow-hidden">
      {(isLoadingServices || isLoadingSystem) && <Loading />}
      <Row>
        <Col className="flex justify-center items-center">
          <Title name={content.hireus} />
        </Col>
      </Row>
      <Row>
        <Col md={11} className="mx-auto border-2 rounded-3xl my-3 py-5">
          <Col>
            <span className="text-xl font-bold p-5">{content.text6}</span>
            <Input
              placeholder={content.yourname}
              onChange={handleChangeInput}
              name="name"
            />
            <Input
              placeholder={content.youremail}
              onChange={handleChangeInput}
              name="email"
            />
            <Input
              placeholder={content.yourphone}
              onChange={handleChangeInput}
              name="phone"
            />
            <TextArea
              placeholder={content.yourmessage}
              onChange={handleChangeInput}
              name="message"
            />
            <Input
              placeholder={content.yourtarget}
              onChange={handleChangeInput}
              name="target_audience"
            />
            <span className="text-lg font-bold pl-3">
              {content.Requestedservices}
            </span>

            <Col className="flex justify-between items-center max-md:flex-col gap-3">
              {services?.data?.data?.map((e) => (
                <InputCeck
                  title={e.name}
                  onChange={(event) => handleChangeCheck(event, e.id)}
                  name="service_type_ids"
                />
              ))}
            </Col>

            <span className="text-lg font-bold pl-3">{content.typesystem}</span>
            <Col className="flex justify-between items-center max-md:flex-col mx-auto gap-3">
              {systems?.data?.data?.map((e, i) => (
                <InputCeck
                  title={e.name}
                  name="system_type_ids"
                  onChange={(event) => handleChangeCheck(event, e.id)}
                />
              ))}
            </Col>
            <Col className="max-md:flex-col flex justify-center items-center gap-4">
              <Col md={6} xs={12}>
                <Input
                  type="date"
                  placeholder={content.Expectedstartingdate}
                  className="w-[95%]"
                  name="expected_starting_date"
                  onChange={handleChangeInput}
                />
              </Col>
              <Col md={6} xs={12}>
                <Input
                  type="date"
                  placeholder={content.Expectedstartingdate}
                  className="w-[97%]"
                  name="expected_ending_date"
                  onChange={handleChangeInput}
                />
              </Col>
            </Col>
            <Row>
              <Input
                placeholder={content.Expectedbudget}
                name="expected_budget"
                onChange={handleChangeInput}
              />
            </Row>
            <Row>
              <span className="text-lg font-bold pl-3 py-3">
                {content.text7}
              </span>
              <div className="rounded-2xl ">
                <input
                  id="file"
                  type="file"
                  className="hidden"
                  accept="application/pdf"
                  name="file"
                  onChange={handleChangeInput}
                />

                <button
                  onClick={() => document.getElementById("file").click()}
                  className="flex gap-3 justify-center border-2 border-Main  object-contain !bg-gray-100 w-full rounded-2xl py-3 text-black text-xl font-bold"
                >
                  {content.UploadDocumant}
                  <span>
                    <FiUpload size={30} color={"gray"} className="" />
                  </span>
                </button>
              </div>
              {viewFile && (
                <div className="flex gap-2 justify-between items-center bg-[#ddd] px-6 py-3 rounded-2xl !w-[95%] mx-auto !mt-5">
                  <div className="flex gap-5">
                    <FaRegFilePdf color="red" size={40} />
                    <h2 className="text-lg font-semibold">{file.name}</h2>
                  </div>
                  <span
                    className="px-3 py-1 hover:bg-red-500 cursor-pointer bg-gray-200 font-bold transition-all duration-200 text-black hover:text-white text-lg rounded-full"
                    onClick={() => handleDeleteFile()}
                  >
                    X
                  </span>
                </div>
              )}
            </Row>
            <Row justify={"end"}>
              <Col md={3} className="pt-6">
                <Button
                  name={content.submit}
                  onClick={() => handleSubmit("posts/store")}
                />
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default HireUs;
