import React from "react";
import { Card, Title } from "../../components/Index";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { fileUrl, language, useFETCH } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import Pagination from "../../Tools/Pagination";
import { useContextTranslate } from "../../Translate/ContextTranslate";
function Project() {
  const { data, isLoading } = useFETCH(`projects`);

  const { content } = useContextTranslate();
  return (
    <Container className="mt-4 bg max-lg:mt-0 overflow-hidden">
      {isLoading && <Loading />}
      <Row className="    ">
        <Col className="flex justify-center items-center pb-12 max-xl:pb-3">
          <Title name={content.ourprojects} />
        </Col>
        <Pagination pageCount={data?.data?.total / data?.data?.per_page}>
          <Col
            lg={11}
            sm={12}
            className="flex justify-center items-center mx-auto max-xl:flex max-xl:justify-row xl:pt-10 gap-2 "
          >
            <Row>
              {data?.data?.data?.map((e, i) => (
                <Col className="max-xl:py-0 !w-[350px] !h-[700px]">
                  <Card
                    image={fileUrl + e.name}
                    index={i}
                    edit={`view/${e.id}`}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Pagination>
      </Row>
    </Container>
  );
}

export default Project;
