import React from "react";
import "./Swipper.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import './Swiper.css';

// import required modules
import { Navigation, Pagination } from "swiper/modules";
import { fileUrl } from "../../Tools/APIs";
import { Card } from "../Index";

function Swipper({ data, isHome, isImage }) {
  return (
    <>
      <Swiper
        spaceBetween={0}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        slidesPerView={4}
        breakpoints={{
          1020: {
            slidesPerView: 4,
          },
          560: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        }}
        className="lg:!pt-20 !pb-10 mx-auto !px-5  "
      >
        {data?.map((e) => (
          <SwiperSlide className="!bg-transparent">
            <Card
              image={isImage ? fileUrl + e.image : fileUrl + e.name}
              name={isImage ? e.name : undefined}
              video={e.type === "video" && fileUrl + e?.name}
              edit={isHome && `projects/view/${e.id}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default Swipper;
