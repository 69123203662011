import React from "react";
import { useParams } from "react-router-dom";
import { clickZoomInImage, fileUrl, useFETCH } from "../../Tools/APIs";
import { formatDateTime } from "../../Tools/FormatDateTime/FormatDateTime";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Title } from "../../components/Index";
import PrintHtml from "../../components/PrintHTML/PrintHtml";

function ContentBlogs() {
  const { id } = useParams();
  const { data, isLoading } = useFETCH(`blogs/${id}`);

  return (
    <Container className="bg overflow-hidden">
      {isLoading && <Loading />}
      <Row>
        <Col className="flex justify-center items-center pb-2">
          <Title name={data?.data?.data?.title} />
        </Col>
        <Col
          md={6}
          className="mx-auto max-md:flex max-md:justify-center max-md:items-center"
        >
          <img
            onClick={clickZoomInImage}
            src={fileUrl + data?.data?.data?.image}
            alt=""
            className="w-full h-[500px] cursor-pointer"
          />
        </Col>
        <Row>
          <Col
            md={11}
            className="mx-auto max-md:flex max-md:justify-center max-md:items-center gap-2"
          >
            <span className="text-black text-start font-bold text-xl ">
              {formatDateTime(data?.data?.data?.updated_at)[0]}
            </span>
            <strong className="text-xl">-</strong>
            <span className="text-black text-start font-bold text-xl ">
              {formatDateTime(data?.data?.data?.updated_at)[1]}
            </span>
          </Col>
        </Row>
        <Col
          md={11}
          className="mx-auto max-md:flex max-md:justify-center max-md:items-center pt-5"
        >
          <span className="text-black text-center font-bold text-xl max-lg:text-lg max-md:text-sm">
            <PrintHtml text={data?.data?.data?.description} />
          </span>
        </Col>
      </Row>
      <Row className=" py-6">
        <Col
          md={11}
          className="mx-auto flex justify-start gap-2 max-md:flex-col"
        >
          <Col md={2}>
            <button className="border-2 border-Main text-black text-center font-semibold text-lg w-full h-full rounded-lg py-2 hover:bg-yellow-400 hover:text-white transition-all duration-200">
              {data?.data?.data?.category.name}
            </button>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default ContentBlogs;
