import { Container } from "../../Tools/Grid-system/index";
import logo from "../../Images/logo.png";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineMenu } from "react-icons/md";

import { useClose } from "../../Tools/APIs";
import Translation from "../../Translate/Translation";
import { useContextTranslate } from "../../Translate/ContextTranslate";

function Navbar() {
  const { content } = useContextTranslate();
  const link = [
    { name: content.home, link: "/", id: "1" },
    { name: content.aboutus, link: "/about-us", id: "2" },
    { name: content.services, link: "/ourservices", id: "3" },
    { name: content.projects, link: "/projects", id: "4" },
    { name: content.blogs, link: "/blogs", id: "5" },
    { name: content.ourprocess, link: "/our-process", id: "6" },
    { name: content.contactus, link: "/lets-talk", id: "7" },
  ];
  const { open, setOpen, mouse } = useClose();
  return (
    <div ref={mouse}>
      <Container className="max-sm:w-full ">
        <div
          className={`z-50 w-full mx-auto  h-[20px] flex justify-start items-center p-3 mt-4 max-md:flex max-md:justify-between max-md:mt-1  `}
        >
          <MdOutlineMenu
            className="md:hidden "
            size={40}
            onClick={() => setOpen(!open)}
          />

          <div className="w-[10%]">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </div>

          <ul
            className={` md:w-full md:flex md:justify-around md:items-center lg: ${
              open
                ? "bg-white shadow-md absolute top-16  rounded-3xl text-center flex-col py-5 px-5 space-y-7 z-50"
                : "hidden"
            }`}
          >
            {link.map((e) => (
              <li
                key={e.id}
                className="text-sm font-bold cursor-pointer hover:text-yellow-500 opacity-80 hover:-translate-y-1 hover:text-2xl transition-all lg:text-xl "
              >
                <NavLink to={e.link}>{e.name}</NavLink>
              </li>
            ))}
            <li>
              <NavLink
                to="/hire-us"
                className={`cursor-pointer py-3 rounded-2xl text-xl text-white font-semibold  text-center bg-gradient-to-l from-Main to-Secondary hover:text-yellow-500 opacity-80 hover:-translate-y-1 hover:text-2xl w-[120px] px-4 transition-all duration-100`}
              >
                {content.hireus}
              </NavLink>
            </li>
          </ul>

          <Translation />
        </div>
      </Container>
    </div>
  );
}

export default Navbar;
