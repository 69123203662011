import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Button, Input, TextArea, Title } from "../../components/Index";
import contact from "../../Images/OBJECTS.png";
import icon_1 from "../../Images/Frame 23.png";
import icon_2 from "../../Images/Frame 24.png";
import icon_3 from "../../Images/Frame 25.png";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { usePOST } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";

function ContactUs({ data, isLoading }) {
  const { handleSubmit, loading, handleChangeInput } = usePOST();
  const { content } = useContextTranslate();

  // useEffect(() => {
  //   AOS.init({

  //   }); // Initialize AOS
  // }, []);

  return (
    <Container className=" mx-auto py-0 overflow-hidden ">
      {(loading || isLoading) && <Loading />}
      <Row className=" max-md:space-y-5 max-md:mx-auto  ">
        <Col className="flex justify-center    ">
          <Title name={content.contactus} />
        </Col>
        <Col
          md={11}
          className="mx-auto flex justify-between items-center max-md:flex-col max-md:gap-7  "
        >
          <Col
            md={5}
            className="border-4  border-t-Main border-b-Main mx-auto space-y-8 pt-7 pb-10 shadow-lg max-lg:w-full h-full bg-white !mb-1"
          >
            <img
              src={contact}
              alt=""
              className="mx-auto "
              // data-aos="fade-up"
              // data-aos-duration="500"
            />
            <Col
              md={9}
              className="flex !mt-12 justify-start items-center mx-auto space-x-4 max-md:px-10  "
            >
              <img
                src={icon_1}
                alt=""
                className=" "
                // data-aos="fade-up"
                // data-aos-duration="500"
              />
              <div className="!mx-2">
                <span
                  className="text-black  text-lg font-semibold "
                  // data-aos="fade-right"
                  // data-aos-duration="500"
                >
                  {content.location}
                </span>
                <p
                  className="text-sm "
                  // data-aos="fade-left"
                  // data-aos-duration="500"
                >
                  {data?.data?.location}
                </p>
              </div>
            </Col>
            <Col
              md={9}
              className="flex justify-start items-center mx-auto space-x-4 max-md:px-10  "
            >
              <img
                src={icon_2}
                alt=""
                className="  "
                // data-aos="fade-up"
                // data-aos-duration="500"
              />
              <div className="!mx-3">
                <span
                  className="text-black text-lg font-semibold "
                  // data-aos="fade-right"
                  // data-aos-duration="500"
                >
                  {content.email}
                </span>
                <p
                  className="text-sm"
                  // data-aos="fade-left"
                  // data-aos-duration="500"
                >
                  {data?.data?.email}
                </p>
              </div>
            </Col>
            <Col
              md={9}
              className="flex justify-start items-center mx-auto space-x-4 max-md:px-10 "
            >
              <img
                src={icon_3}
                alt=""
                className=" "
                // data-aos="fade-up"
                // data-aos-duration="500"
              />
              <div className="!mx-3">
                <span
                  className="text-black text-lg font-semibold"
                  // data-aos="fade-right"
                  // data-aos-duration="500"
                >
                  {content.telephone}
                </span>
                <p
                  className="text-sm"
                  dir="ltr"
                  // data-aos="fade-left"
                  // data-aos-duration="500"
                >
                  {data?.data?.telephone}
                </p>
              </div>
            </Col>
          </Col>
          <Col
            md={5}
            className="border-4 border-t-Main border-b-Main  mx-auto max-lg:w-full max-lg:h-full  shadow-lg bg-white "
          >
            <Input
              title={content.firstname}
              placeholder={content.enteryourname}
              onChange={handleChangeInput}
              name="name"
            />
            <Input
              title={content.email}
              placeholder={content.enteryouremail}
              onChange={handleChangeInput}
              name="email"
            />
            <TextArea
              title={content.message}
              placeholder={content.enteryourmessage}
              onChange={handleChangeInput}
              name="message"
            />
            <Row className="pt-2 flex justify-center items-center">
              <Col md={6}>
                <Button
                  name={content.submit}
                  className="max-md:w-1/2 max-md:mx-auto"
                  onClick={() => handleSubmit("messages", false, true)}
                />
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactUs;
