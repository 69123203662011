import React from "react";
import { Link } from "react-router-dom";
import PrintHtml from "../PrintHTML/PrintHtml";
import { Col } from "../../Tools/Grid-system";

function Blog1({ picture, date, time, info, className, edit }) {
  return (
    <div className="hover:scale-105 transition-all duration-150">
      <Link to={edit || ""}>
        <div
          // data-aos="fade-right"
          // data-aos-offset="200"
          // data-aos-easing="ease-in-sine"
          // data-aos-duration="700"
          className={`${className}`}
        >
          <Col md={12} col={8} className="mx-auto">
            {picture && (
              <img
                src={picture}
                alt=""
                className="max-lg:w-full max-lg:h-[80%]"
              />
            )}
          </Col>

          <p className="text-slate-500 mt-2 text-xl max-md:text-center  text-md max-md:text-xl ">
            {time} <strong className="text-xl">-</strong> {date}
          </p>
          <p className="text-black text-2xl font-bold max-md:text-center  text-md max-md:text-xl max-md:pt-1 ">
            <PrintHtml text={info} />
          </p>
        </div>
      </Link>
    </div>
  );
}

export default Blog1;
