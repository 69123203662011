import React from "react";
import { AiOutlineLinkedin } from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";
import { LuFacebook } from "react-icons/lu";
import { TbBrandTelegram } from "react-icons/tb";
import { FaWhatsapp } from "react-icons/fa";

import { NavLink } from "react-router-dom";
import { Button } from "../../components/Index";
import logo from "../../Images/Group 103.png";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { usePOST } from "../../Tools/APIs";

const socialMediaIcons = {
  facebook: LuFacebook,
  instagram: FiInstagram,
  linkedin: AiOutlineLinkedin,
  telegram: TbBrandTelegram,
  whatsapp: FaWhatsapp,
};

function Footer({ data }) {
  const { content } = useContextTranslate();
  const { handleSubmit, setFormData } = usePOST({ email: "" });

  const socialMedia = data?.data?.social_media;

  const socialMediaLinks = socialMedia && [
    {
      link: socialMedia["facebook"],
      icon: socialMediaIcons["facebook"],
    },
    {
      link: socialMedia["instagram"],
      icon: socialMediaIcons["instagram"],
    },
    {
      link: socialMedia["linkedin"],
      icon: socialMediaIcons["linkedin"],
    },
    {
      link: socialMedia["telegram"],
      icon: socialMediaIcons["telegram"],
    },
    {
      link: socialMedia["whatsapp"],
      icon: socialMediaIcons["whatsapp"],
    },
  ];

  const link = [
    { name: content.home, link: "/", id: "1" },
    { name: content.aboutus, link: "/about-us", id: "2" },
    { name: content.services, link: "/ourservices", id: "3" },
    { name: content.projects, link: "/projects", id: "4" },
  ];
  return (
    <Container className="!pb-0">
      <Col className="  w-full mx-auto flex flex-col items-center">
        <div className="w-full h-[1px] bg-Secondary"></div>
        <Row className="flex md:justify-around md:items-center w-full">
          <Col xl={3} className=" flex justify-center items-center pt-5 ">
            <img
              src={logo}
              alt=""
              className=" max-xl:w-[50%] w-full h-full  "
            />
          </Col>
          <Col
            xl={2}
            md={4}
            className="flex-col space-y-3 pt-14  max-md:flex max-md:justify-center max-md:items-center"
          >
            <h1 className="text-black  text-2xl font-bold text-center ">
              {content.pages}
            </h1>
            <ul className=" text-sxl text-black  space-y-2 mx-auto">
              {link.map((e) => (
                <li
                  key={e.id}
                  className="text-black text-xl text-center font-semibold "
                >
                  <NavLink to={e.link}>{e.name}</NavLink>
                </li>
              ))}
            </ul>
          </Col>
          <Col
            xl={2}
            md={4}
            className="flex-col space-y-3 pt-14 max-md:pt-3 max-md:flex max-md:justify-center max-md:items-center"
          >
            <h1 className="text-black  text-2xl font-bold max-md:mt-10 text-center  ">
              {content.contactus}
            </h1>
            <ul className=" text-xl text-black space-y-2 max-xl:space-y-2">
              {data?.data?.contact_us?.map((e, i) => (
                <li
                  key={i}
                  className="text-black text-xl text-center font-semibold"
                >
                  {e}
                </li>
              ))}
            </ul>
          </Col>
          <Col xl={3} md={4} className="flex-col space-y-4  pt-20 max-md:pt-3 ">
            <h1 className="text-black text-start text-2xl font-bold max-md:text-center max-md:mt-10 max-md:mb-3  ">
              {content.Letsdoit}
            </h1>
            <Col className=" ">
              <Col
                xl={7}
                md={7}
                sm={8}
                className="flex justify-between gap-3 items-center max-md:mx-auto max-md:flex max-md:justify-between max-md:items-center "
              >
                {socialMediaLinks?.map((e) => (
                  <a href={e.link} target="_blank">
                    {e.icon && (
                      <e.icon
                        color="black"
                        style={{ borderBlockColor: "black" }}
                        size={30}
                      />
                    )}
                  </a>
                ))}
              </Col>
              <h1 className="text-black text-start text-xl font-bold max-md:text-center max-xl:text-sm">
                {content.subscribe}
              </h1>
              <Col className="max-md:flex max-md:justify-center max-md:items-center  ">
                <h1 className="text-black text-start text-sm font-bold  max-md:text-center">
                  {
                    content.subscribetostaytunedfornewwebdesignandlatestupdatesLetsdoit
                  }
                </h1>
              </Col>
              <Col>
                <div className=" flex justify-center items-center max-md:flex max-md:justify-center max-md:items-center max-md:mx-auto max-md:pt-5 pt-1 ">
                  <div
                    style={{
                      border: "2px solid #7662A4",
                      borderRadius: "10px",
                    }}
                    className=" bg-gray-100  h-[35px] flex justify-center items-center rounded-2xl  "
                  >
                    <input
                      onChange={(e) => setFormData({ email: e.target.value })}
                      placeholder={content.email}
                      type="email"
                      className="bg-gray-100 text-start w-full   text-black max-md:text-start  font-bold"
                    />
                    <Button
                      onClick={() => handleSubmit("subscribers", false, true)}
                      name={content.send}
                      className="h-[35px] w-full !pl-5 !pr-5 !py-1 !rounded-lg text-center "
                    />
                  </div>
                </div>
              </Col>
            </Col>
          </Col>
        </Row>
        <Row className="!w-full">
          <div className=" !mt-3 h-[1px] bg-gradient-to-l from-Main to-Secondary w-full"></div>

          <p className=" font-bold  text-center pt-1">
            © 2024 All Rights Reserved
          </p>
        </Row>
      </Col>
    </Container>
  );
}

export default Footer;
