import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useFilter } from "../APIs";
import Loading from "../Loading";

const Pagination = ({ pageCount, children, isLoading }) => {
  const { handlePageClick, searchParams } = useFilter();
  const [selectedPage, setSelectedPage] = useState(
    parseInt(searchParams.get("page")) - 1 || 0
  );

  useEffect(() => {
    setSelectedPage(parseInt(searchParams.get("page")) - 1 || 0);
  }, [searchParams]);

  const onPageChange = (data) => {
    handlePageClick(data);
    setSelectedPage(data.selected);
  };

  return (
    <>
      <div>{children}</div>
      {isLoading ? <Loading /> : ""}
      <div className="flex justify-center my-3">
        <ReactPaginate
          className=" pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          // ref={a}
          pageCount={pageCount || 0}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={onPageChange}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          forcePage={selectedPage}
        />
      </div>
    </>
  );
};

export default Pagination;
