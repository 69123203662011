import React from "react";

import "aos/dist/aos.css";
import { Blog1, Blog2, Title } from "../../components/Index";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import { formatDateTime } from "../../Tools/FormatDateTime/FormatDateTime";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import Pagination from "../../Tools/Pagination";
import { useContextTranslate } from "../../Translate/ContextTranslate";

function Blog() {
  const { data, isLoading } = useFETCH(`blogs`);
  const { content } = useContextTranslate();

  // useEffect(() => {
  //   AOS.init({}); // Initialize AOS
  // }, []);
  return (
    <Container className="">
      {isLoading && <Loading />}
      <Row>
        <Col className=" flex justify-center items-center">
          <Title name={content.blogs} />
        </Col>
        <Pagination pageCount={data?.data?.total / data?.data?.per_page}>
          <Row className="mx-auto pt-1 flex justify-around max-md:space-y-5">
            <Col
              md={12}
              className="mx-auto flex justify-between max-md:flex-col"
            >
              <Col
                md={5}
                className="space-y-5 flex-col max-md:flex max-md:justify-center max-md:items-center mx-auto "
              >
                {data?.data?.data?.map(
                  (e, i) =>
                    i % 4 === 0 && (
                      <Blog1
                        picture={fileUrl + e.image}
                        time={formatDateTime(e.created_at)[0]}
                        date={formatDateTime(e.created_at)[1]}
                        info={e.description}
                        edit={`view/${e.id}`}
                        className="mt-10"
                      />
                    )
                )}
              </Col>
              <Col
                md={5}
                className="mx-auto flex justify-between max-md:flex-col gap-11 "
              >
                <Row>
                  {data?.data?.data?.map(
                    (e, i) =>
                      i % 4 !== 0 && (
                        <Blog2
                          picture={fileUrl + e.image}
                          info={e.description}
                          edit={`view/${e.id}`}
                          time={formatDateTime(e.created_at)[0]}
                          date={formatDateTime(e.created_at)[1]}
                        />
                      )
                  )}
                </Row>
              </Col>
            </Col>
          </Row>
        </Pagination>
      </Row>
    </Container>
  );
}

export default Blog;
