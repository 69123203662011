import React, { createContext, useContext, useEffect, useState } from "react";
import Translate from "./translate.json";
const ContextAPI = createContext({});

window.localStorage.getItem("language")
  ? window.localStorage.getItem("language")
  : window.localStorage.setItem("language", "en");

const ContextTranslate = ({ children }) => {
  const [translate, setTranslate] = useState(
    window.localStorage.getItem("language")
  );
  
  const [content, setContent] = useState({});
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    if (window.localStorage.getItem("language") === "ar") {
      document.body.style.direction = "rtl";
      setContent(Translate.ar);
    }
    if (window.localStorage.getItem("language") === "en") {
      document.body.style.direction = "ltr";
      setContent(Translate.en);
    }
  }, [translate, content]);

  const changeLanguage = () => {
    setTranslate("ar");
    window.localStorage.setItem("language", "ar");
    window.location.reload();
  };
  const changeLanguage2 = () => {
    setTranslate("en");
    window.localStorage.setItem("language", "en");
    window.location.reload();
  };

  return (
    <ContextAPI.Provider
      value={{
        showPopUp,
        content,
        setShowPopUp,
        changeLanguage,
        changeLanguage2,
      }}
    >
      {children}
    </ContextAPI.Provider>
  );
};

export default ContextTranslate;

export const useContextTranslate = () => {
  return useContext(ContextAPI);
};
