import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Footer, Navbar } from "./Layout/Index";
import {
  Blog,
  ContactUs,
  ContentBlogs,
  ContentProject,
  ContentServices,
  HireUs,
  Home,
  Learn,
  OurProcess,
  Project,
  Services,
} from "./pages/Index";
import { useFETCH } from "./Tools/APIs";

const App = () => {
  const { data, isLoading } = useFETCH(`info`);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div style={{ transition: "0.3s" }}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home data={data} />} />
        <Route path="our-process" element={<OurProcess />} />
        <Route path="ourservices">
          <Route index element={<Services />} />
          <Route path="view/:id" element={<ContentServices />} />
        </Route>
        <Route path="projects">
          <Route index element={<Project />} />
          <Route path="view/:id" element={<ContentProject />} />
        </Route>
        <Route path="about-us">
          <Route index element={<Learn data={data} isLoading={isLoading} />} />
        </Route>

        <Route path="blogs">
          <Route index element={<Blog />} />
          <Route path="view/:id" element={<ContentBlogs />} />
        </Route>
        <Route
          path="lets-talk"
          element={<ContactUs data={data} isLoading={isLoading} />}
        />
        <Route path="hire-us" element={<HireUs />} />
      </Routes>
      <Footer data={data} />
    </div>
  );
};

export default App;
