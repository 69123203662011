function Inputceck({
  title,
  name,
  value,
  type,
  onChange,
  placeholder,
  className,
}) {
  return (
    <div className="my-7 border-2 border-Main flex justify-center items-center mx-auto rounded-3xl flex-1">
      <div className="flex justify-start p-5 items-center flex-wrap w-full">
        <input
          type={type || "checkbox"}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          max={100}
          min={0}
          className={` w-20 transform scale-x-150 scale-y-150 ${className}`}
        />
        {title && <h1 className=" text-center font-bold text-lg">{title}</h1>}
      </div>
    </div>
  );
}
export default Inputceck;
