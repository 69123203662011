import React from "react";
import { Col, Container, Row } from "../../Tools/Grid-system";
import home_1 from "../../Images/home-image-1 1.png";
import Title from "../../components/Title/Title";
import Button from "../../components/Button/Button";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import PrintHtml from "../../components/PrintHTML/PrintHtml";
import { fileUrl } from "../../Tools/APIs";

function AboutUs({ data }) {
  const { content } = useContextTranslate();

  console.log(data?.data?.about.text)
  return (
    <Container className="mt-20 max-xl:mt-0 mx-auto">
      <Row className="flex justify-center  gap-5 max-lg:flex max-lg:justify-between  lg:mx-auto max-xl:gap-0 mx-auto h-full ">
        <Col lg={4} md={5}>
          <img
            // data-aos="fade-left"
            // data-aos-offset="200"
            // data-aos-easing="ease-in-out"
            // data-aos-duration="500"
            src={fileUrl + data?.data?.about.image}
            alt=""
            className="w-[90%] h-full max-lg:h-[600px] max-lg:w-full max-lg:mx-5 "
          />
        </Col>
        <Col
          lg={7}
          md={6}
          className="m-4 max-xl:m-0 flex flex-col justify-between"
        >
          <div className="flex flex-col gap-5">
            <Title name={content.aboutus} />
            <div
              // data-aos="fade-right"
              // data-aos-offset="300"
              // data-aos-easing="ease-in-sine"
              // data-aos-duration="500"
              className="mb-5 max-lg:mb-1"
            >
              <PrintHtml text={data?.data?.about.text} />
            </div>
          </div>

          <Button
            name={content.learnmore}
            className="!w-[250px] max-md:w-full "
            link="/about-us"
          />
        </Col>
      </Row>
    </Container>
  );
}

export default AboutUs;
