import "swiper/css";
import "swiper/css/pagination";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Swipper, Title } from "../../components/Index";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import {
  clickZoomInImage,
  fileUrl,
  language,
  useFETCH,
  useFilter,
} from "../../Tools/APIs";
import { useParams } from "react-router-dom";
import PrintHtml from "../../components/PrintHTML/PrintHtml";
import Loading from "../../Tools/Loading";

function ContentServices() {
  const { id } = useParams();
  const { data, isLoading } = useFETCH(`services/${id}`);

  const { content } = useContextTranslate();
  return (
    <Container className="bg overflow-hidden ">
      {isLoading && <Loading />}
      <Row className="mx-auto">
        <Col className="flex justify-center items-center mx-auto  ">
          <Title name={content.ourservices} />
        </Col>
        <Row gap={5}>
          <Col md={6} className="mx-auto">
            <img
              src={fileUrl + data?.data?.data?.image}
              alt=""
              onClick={clickZoomInImage}
              className="cursor-pointer"
            />
          </Col>
          <Col
            md={11}
            className="mx-auto max-md:flex max-md:justify-center max-md:items-center"
          >
            <span className="text-black text-start font-bold text-2xl ">
              {data?.data?.data?.name}
            </span>
          </Col>
        </Row>

        <Row className="mx-auto">
          <Col md={11} className="mx-auto pt-1">
            <PrintHtml text={data?.data?.data?.description} />
          </Col>
        </Row>
        <Row className="mx-auto">
          <Col className="mx-auto pt-5 ">
            <Swipper data={data?.data?.data?.files} />
          </Col>
        </Row>
      </Row>
    </Container>
  );
}

export default ContentServices;
