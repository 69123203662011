import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./Card.css";
import { clickZoomInImage } from "../../Tools/APIs";

function Card({ index, name, className, image, edit, video }) {
  const [isHovered, setIsHovered] = useState(false);
  const { id } = useParams();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={`relative mx-auto p-5 w-full image-wrapper ${
        isHovered ? "enlarged" : ""
      } ${index % 2 === 0 ? "xl:-mt-24" : ""} ${className}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link to={edit || ""}>
        {image && (
          <img
            src={image}
            alt=""
            className="w-full"
            onClick={id && clickZoomInImage}
          />
        )}
        {video && <video src={video} className="w-full" />}
        {name && <p className="text-overlay text-lg">{name}</p>}
      </Link>
    </div>
  );
}

export default Card;
