import "aos/dist/aos.css";
import React from "react";
import { Cursor, useTypewriter } from "react-simple-typewriter";
import { direction, fileUrl } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import Button from "../../components/Button/Button";
import { OurServices } from "../../components/Index";
import { AboutUs, Blogs, ContactUs, Projects } from "../Index";

function Home({ data }) {
  const { content } = useContextTranslate();
  const [text] = useTypewriter({
    words: ["ICR"],
    loop: {},
    typeSpeed: 120,
    deleteSpeed: 100,
  });

  // useEffect(() => {
  //   AOS.init({
  //     offset: 200,
  //     delay: 300,
  //     easing: "ease-in-out",
  //     duration: 1000,
  //     // Whether to animate elements only once
  //   }); // Initialize AOS
  // }, []);

  return (
    <Container className=" mx-auto overflow-hidden">
      <Row className=" mx-auto">
        <Col lg={10} className="mx-auto pt-44 max-xl:pt-0 ">
          <Col className="flex justify-center items-center !mb-10 mx-auto">
            <h3
              className="text-black text-3xl font-bold text-center "
              // data-aos="fade-left"
              dir="ltr"
              // data-aos-duration="1000"
            >
              <span
                className="!text-7xl font-bold"
                style={{ color: "#f9b226" }}
              >
                {text[0]}
              </span>
              <span
                className="!text-7xl font-bold"
                style={{ color: "#36549d" }}
              >
                {text[1]}
              </span>
              <span
                className="!text-7xl font-bold"
                style={{ color: "#8F68A7" }}
              >
                {text[2]}
              </span>
              <span className="!text-6xl font-bold" dir="ltr">
                <Cursor cursorColor="#f9b226" />
              </span>
            </h3>
          </Col>

          <Col className="flex justify-center items-center">
            <span
              // data-aos="fade-right"
              // data-aos-duration="1000"
              className="text-xl text-slate-700 font-semibold text-center"
            >
              is a leading software development company that specializes in
              delivering top-notch IT solutions. With over 15 years of
              experience in the industry, we have successfully catered to the
              needs of numerous clients, ranging from small startups to large
              multinational corporations.
            </span>
          </Col>
          <Col className="flex justify-center items-center  gap-5 mt-3   ">
            <Button
              name={content.hireus}
              className="!w-[140px] "
              link="/hire-us"
              // data-aos="fade-left"
            />
            <a
              className="cursor-pointer px-5 border border-Secondary py-3 my-auto rounded-2xl text-white font-semibold transition-colors text-lg text-center bg-gradient-to-l from-Main to-Secondary hover:text-yellow-400 hover:text-[19px]"
              href={fileUrl + data?.data?.portfolio}
              target="_blank"
            >
              {content.portfolio}
            </a>
          </Col>
        </Col>
      </Row>
      <AboutUs data={data} />
      <OurServices />
      <Projects />
      <Blogs />
      <ContactUs data={data} />
    </Container>
  );
}

export default Home;
