import React from "react";
import { Link } from "react-router-dom";
import { Col } from "../../Tools/Grid-system";
import PrintHtml from "../PrintHTML/PrintHtml";

function CardMel({ picture, title, text, a, edit }) {
  return (
    <div
      className="  bg-gradient-to-l from-Main to-Secondary w-full h-full rounded-ee-[40px] rounded-ss-[100px] rounded-se-[60px] relative shadow-md my-10"
      // data-aos="zoom-out-up"
      // data-aos-duration="700"
    >
      <div className="rounded-full bg-gradient-to-l from-Main to-Secondary absolute -top-3 right-1/2 translate-x-1/2 -translate-y-1/2 flex justify-center items-center ">
        {picture && (
          <img
            src={picture}
            alt=""
            className="object-cover w-20 h-20 rounded-full"
          />
        )}
      </div>
      <div className="bg-white w-full h-full rounded-ee-[100px] rounded-ss-[120px] space-y-5 p-2 rounded-se-[60px] mx-auto flex-col pt-12 gap-10">
        <p className="text-2xl text-center font-bold">{title}</p>
        <Col sm={11} className="mx-auto">
          <p
            className="text-sm text-center font-semibold mx-auto overflow-auto"
            style={{ color: "#0F172A " }}
          >
            <PrintHtml text={text} />
          </p>
        </Col>
        <Link to={edit || ""} className="flex px-5 w-fit">
          <p className="text-xl w-fit font-semibold bg-Main px-5 py-3 flex justify-center items-center border-Secondary rounded-3xl hover:text-yellow-500  text-white ">
            {a}
          </p>
        </Link>
      </div>
    </div>
  );
}

export default CardMel;
