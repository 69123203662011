function TextArea({ title, name, value, onChange, placeholder }) {
  return (
    <div
      className="px-5 "
      //  data-aos="fade-up" data-aos-duration="1000"
    >
      <h1 className="text-start text-xl text-black font-bold mb-3   ">
        {title}
      </h1>
      <textarea
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="border-2 border-Main outline-none  bg-gray-100 py-3 rounded-xl block w-full px-3 h-[150px] text-start"
      />
    </div>
  );
}
export default TextArea;
