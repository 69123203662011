import React from "react";
import l2 from "../../Images/Group 79.png";
import l1 from "../../Images/design 1.png";
import l3 from "../../Images/nimbus_marketing.png";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Title from "../Title/Title";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Button, CardMel } from "../Index";
import { direction, fileUrl, language, useFETCH } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";

function OurServices() {
  const { data, isLoading } = useFETCH(`services?is_speacial=1`);

  const { content } = useContextTranslate();
  return (
    <Container className="mx-auto">
      {isLoading && <Loading />}
      <Row>
        <Col className="flex justify-center items-center ">
          <Title name={content.ourservices} />
        </Col>
      </Row>

      <Row className="flex justify-between items-center mx-auto pt-16 max-lg:pt-10 ">
        <Col
          lg={12}
          className="flex justify-center items-center max-lg:flex-col pb-5 flex-wrap gap-1"
        >
          {data?.data?.data?.map((e) => (
            <Col
              lg={3}
              md={8}
              xs={11}
              className="mx-auto hover:scale-105 transition-all duration-150"
            >
              <CardMel
                picture={fileUrl + e.image}
                title={e.name}
                text={e.description}
                a={content.view}
                edit={`ourservices/view/${e.id}`}
              />
            </Col>
          ))}
        </Col>
      </Row>

      <Col
        className="flex justify-end max-md:flex-col mt-3"
        style={{ direction: !direction ? "ltr" : "ltr" }}
      >
        <Col md={2} className="mr-16 max-md:mr-0 ">
          <Button name={content.viewall} className="" link="ourservices" />
        </Col>
      </Col>
    </Container>
  );
}

export default OurServices;
