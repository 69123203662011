import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Col, Container, Row } from "../../Tools/Grid-system";
import home_1 from "../../Images/home-image-1 1.png";
import Title from "../../components/Title/Title";
import v_1 from "../../Images/Vector (7).png";
import v_2 from "../../Images/Group (2).png";
import v_3 from "../../Images/Group (3).png";
import v_4 from "../../Images/Group (4).png";
import v_5 from "../../Images/Group (5).png";
import vec from "../../Images/Group (6).png";

import { Circle } from "../../components/Index";
import { useEffect } from "react";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { fileUrl } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";

function Learn({ data, isLoading }) {
  const { content } = useContextTranslate();
  // useEffect(() => {
  //   AOS.init({}); // Initialize AOS
  // }, []);
  return (
    <Container className=" bg overflow-hidden">
      {isLoading && <Loading />}
      <Col className=" flex justify-center !text-center items-center">
        <Title name={content.aboutus} />
      </Col>
      <Row className="flex justify-center items-center gap-5 mx-auto ">
        <Col md={4}>
          <img
            src={fileUrl + data?.data?.about.image}
            alt=""
            className="w-full mx-auto max-lg:w-full"
            // data-aos="flip-left"
            // data-aos-duration="0"
          />
        </Col>
        <Col md={7} className="m-4">
          <div
            className="mb-5"
            //  data-aos="flip-right" data-aos-duration="0"
          >
            {data?.data?.about.text}
          </div>
        </Col>
      </Row>
      <Col className=" flex justify-center items-center">
        <Title name={content.ourmission} />
      </Col>
      <Row className="flex justify-center items-center  gap-5 mx-auto">
        <Col md={7} className="m-4 max-md:order-3">
          <div
            className="mb-5"
            //  data-aos="flip-right" data-aos-duration="0"
          >
            {data?.data?.our_job.text}
          </div>
        </Col>
        <Col md={4} className="max-md:order-1">
          <img
            src={fileUrl + data?.data?.our_job.image}
            alt=""
            className="w-full max-lg:w-full"
            // data-aos="flip-left"
            // data-aos-duration="0"
          />
        </Col>
      </Row>
      <Row>
        <Col className=" flex justify-center items-center ">
          <Title name={content.ourvalue} />
        </Col>
        <Row>
          <Col
            lg={10}
            xs={12}
            className="flex justify-center items-center mx-auto  max-md:flex max-md:justify-center  max-md:items-center  "
          >
            <img
              src={vec}
              alt=""
              className="w-full max-lg:hidden"
              // data-aos="fade-zoom-in"
              // data-aos-easing="ease-in-back"
              // data-aos-delay="300"
              // data-aos-offset="0"
            />
          </Col>

          <Col className=" flex justify-between items-center w-full  mx-2  max-md:flex-col ">
            <Col md={2} className="flex justify-center items-center">
              <Circle img={v_1} title={content.INTEGRITY} />
            </Col>
            <Col md={2} className="flex justify-center items-center">
              <Circle img={v_2} title={content.GOALS} />
            </Col>
            <Col md={2} className="flex justify-center items-center">
              <Circle img={v_3} title={content.INNOVATION} />
            </Col>
            <Col md={2} className="flex justify-center items-center">
              <Circle img={v_4} title={content.QUALITY} />
            </Col>
            <Col md={2} className="flex justify-center items-center">
              <Circle img={v_5} title={content.EXCELLENCE} />
            </Col>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}

export default Learn;
