import React from "react";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Button, Swipper, Title } from "../Index";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { direction, language, useFETCH } from "../../Tools/APIs";
function Projects() {
  const { data, isLoading } = useFETCH(`projects`);

  const { content } = useContextTranslate();
  console.log(data?.data?.data)
  return (
    <>
      <Container className=" mx-auto ">
        <Row className="   ">
          <Col className="flex justify-center items-center pb-1 max-lg-pb-0">
            <Title name={content.ourprojects} />
          </Col>
          <Col className=" mx-auto ">
            <Swipper data={data?.data?.data?.slice(0, 7)} isImage/>
          </Col>
        </Row>

        <Col
          className="flex justify-end max-md:flex-col mt-3"
          style={{ direction: !direction ? "ltr" : "ltr" }}
        >
          <Col md={2} className="mr-12 max-md:mr-0 ">
            <Button name={content.viewall} className="" link="/projects" />
          </Col>
        </Col>
      </Container>
    </>
  );
}

export default Projects;
