import React, { useEffect } from "react";
import { Container, Row, Col } from "../../Tools/Grid-system";
import { Blog1, Blog2, Button, Title } from "../Index";
import image_blog from "../../Images/Image.png";
import image_blog_2 from "../../Images/Image (1).png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { direction, fileUrl, language, useFETCH } from "../../Tools/APIs";
import { formatDateTime } from "../../Tools/FormatDateTime/FormatDateTime";

function Blogs() {
  const { content } = useContextTranslate();
  const { data, isLoading } = useFETCH(`blogs`);

  useEffect(() => {
    AOS.init({}); // Initialize AOS
  }, []);
  return (
    <Container className="mx-auto">
      <Row>
        <Col className=" flex justify-center items-center">
          <Title name={content.blogs} />
        </Col>
        <Row className="mx-auto pt-1 flex justify-around max-md:space-y-5">
          <Col
            md={11}
            className="mx-auto flex justify-between max-md:flex-col "
          >
            <Col
              md={5}
              className="space-y-5 flex-col max-md:flex max-md:justify-center max-md:items-center mx-auto "
            >
              {data?.data?.data
                ?.slice(0, 4)
                .map(
                  (e, i) =>
                    i % 4 === 0 && (
                      <Blog1
                        picture={fileUrl + e.image}
                        time={formatDateTime(e.created_at)[0]}
                        date={formatDateTime(e.created_at)[1]}
                        info={e.description}
                        edit={`blogs/view/${e.id}`}
                        className="mt-10"
                      />
                    )
                )}
            </Col>
            <Col
              md={5}
              className="mx-auto flex justify-between max-md:flex-col gap-11 "
            >
              <Row>
                {data?.data?.data
                  ?.slice(0, 4)
                  .map(
                    (e, i) =>
                      i % 4 !== 0 && (
                        <Blog2
                          picture={fileUrl + e.image}
                          info={e.description}
                          edit={`blogs/view/${e.id}`}
                          time={formatDateTime(e.created_at)[0]}
                          date={formatDateTime(e.created_at)[1]}
                        />
                      )
                  )}
              </Row>
            </Col>
          </Col>
        </Row>
        <Col
          className="flex justify-end max-md:flex-col !mt-5"
          style={{ direction: !direction ? "ltr" : "ltr" }}
        >
          <Col md={2} className="mr-16 max-md:mr-0 ">
            <Button name={content.viewall} className="" link="blogs" />
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default Blogs;
