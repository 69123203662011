function Title({ name, link }) {
  return (
    <div
      // data-aos="fade-down"
      // data-aos-easing="ease"
      // data-aos-duration="500"
      className="text-4xl text-start font-bold  decoration-Secondary decoration-4 my-5 mb-10 flex  justify-center  gap-5 max-sm:text-xl"
    >
      <p className="w-[6px] h-[40px] bg-Secondary"></p> {name}
    </div>
  );
}
export default Title;
